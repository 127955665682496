import { Toast } from 'vant'
import { i18n } from '@/main'

class Loading {
  showLoading(message = i18n.t('loading'), options = {}) {
    Toast.loading({
      message,
      duration: 0,
      forbidClick: true,
      closeOnPopstate: true,
      ...options,
    })
  }
  hideLoading() {
    Toast.clear()
  }
}

export default new Loading()
