import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import { thenResolve } from '@/utils/index'

const clueServices = {
  /**
   * 根据角色查询人员信息
  */
  async getclueList(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/list`, params).then(thenResolve)
  },
  /**
   * 线索列表数据统计
  */
  async getListCount(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/listCount`, params).then(thenResolve)
  },
  async getClueDetail(params) {
    return await request.get(`${SERVER.LEAD_SERVICE}/api/v1/detail`, { params }).then(thenResolve)
  },
  /**
   * 创建线索
  */
  async creatClue(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/create`, params).then(thenResolve)
  },
  /**
   * 线索跟进
  */
  async followClue(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/follow`, params).then(thenResolve)
  },
  /**
   * 获取意向评级
  */
  async getLevel(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/getLevel`, params).then(thenResolve)
  },
  /**
   * 线索判重
  */
  async clueExist(params) {
    return await request.get(`${SERVER.LEAD_SERVICE}/api/v1/exist`, { params }).then(thenResolve)
  },
  /**
   * 获取线索评级
  */
  async getClueLevel(params) {
    const result = await request.post(`${SERVER.LEAD_SERVICE}/api/v1/getLevel`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '评级获取失败！')
      return
    }
    return data
  },
  /**
   * 计算最大跟进时间
  */
  async getMaxPlaneTime(params) {
    const result = await request.post(`${SERVER.LEAD_SERVICE}/api/v2/maxPlanTime`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取跟进时间失败！')
      return
    }
    return data
  },
  /**
   * 线索重新分配
   */
  async clueDistribution(params) {
    const result = await request.post(`${SERVER.LEAD_SERVICE}/api/v1/transfer`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '线索分配失败')
      return {}
    }
    return data
  },
  /**
   * 获取线索轨迹信息
   */
  async getTrips(params) {
    return await request.get(`${SERVER.LEAD_SERVICE}/api/v1/trips`, { params }).then(thenResolve)
  },
  /**
   * 领取线索
   */
  async receiveClue(params) {
    const result = await request.get(`${SERVER.LEAD_SERVICE}/api/v1/receive`, { params })
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '线索领取失败')
      return false
    }
    return true
  },
  /**
   * 领取回收线索
   */
  async receiveRecycle(params) {
    const result = await request.get(`${SERVER.LEAD_SERVICE}/api/v1/receiveRecycle`, { params })
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '线索领取失败')
      return false
    }
    return true
  },
  /**
   * 修改线索信息
   */
  updateClueDetail(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/api/v1/modify`, params).then(thenResolve)
  },
  /**
   * 获取master和buddy，排除了自己
   */
  async getMasterBuddy() {
    const result = await request.get(`${SERVER.ADMIN_SERVICE}/api/v1/employee/getMasterAndBuddyEmp`, {})
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg)
      return false
    }
    return data
  },
  /**
   * 授权接待
   */
  async empowerPost(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/empower`, params).then(thenResolve)
  },
  /**
   * 取消授权
   */
  async cancelEmpowerPost(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/cancelEmpower`, params).then(thenResolve)
  },
  /**
   * 修改授权
   */
  async updateEmpowerPost(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/updateEmpower`, params).then(thenResolve)
  },
  /**
   * 店内线索查询接口
   */
  async dealerClueListPost(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/dealerList`, params).then(thenResolve)
  },
  /**
   * 申请授权
   */
  async applyEmpowerPost(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/applyEmpower`, params).then(thenResolve)
  },
  async getHatchClueDetail(params) {
    return await request.get(`${SERVER.LEAD_SERVICE}/api/v1/hatchDetail`, { params }).then(thenResolve)
  },
  // 线索暂存
  addClueDraft(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/api/v1/draft`, params).then(thenResolve)
  },
  // 查询暂存
  getClueDraft(params) {
    return request.get(`${SERVER.LEAD_SERVICE}/api/v1/lead/draft`, { params }).then(thenResolve)
  },
  // 线索降级数据count查询
  downGradeCount(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/api/v1/downGradeCount`, params).then(thenResolve)
  },
  // 查询线索标签
  getCluesTags(params) {
    return request.get(`${SERVER.LEAD_SERVICE}/api/v1/query-labels`, { params }).then(thenResolve)
  },
  // 修改线索标签内容
  updateCluesTags(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/api/v1/modify-labels`, params).then(thenResolve)
  },
  // 线索授权审核
  examineEmpower(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/api/v1/examineEmpower`, params).then(thenResolve)
  },
  // 试驾侧通过手机号查询线索信息
  getMobileToClues(params) {
    return request.get(`${SERVER.LEAD_SERVICE}/api/v1/getLeadInfo`, { params }).then(thenResolve)
  },
  // 通过客户ID查询是否有线索
  hatchDetailByWechat(params) {
    return request.get(`${SERVER.LEAD_SERVICE}/api/v1/detailByWechat`, { params })
  },
  // 短信邀请加微
  async inviteByLead(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/chatGroupTask/inviteByLead`, params).then(thenResolve)
  },
  // 生成智慧号
  async generatePhonex(params) {
    return await request.get(`${SERVER.LEAD_SERVICE}/api/v1/unicom/phone_x/generate`, { params })
  },
  // d-showRoom对接
  async getShowRoomUrl(params) {
    return await request.get(`${SERVER.LEAD_SERVICE}/api/v1/dshowroom/integrationUrl`, { params }).then(thenResolve)
  },
  // 解绑微信
  unBindWechat: async (params)=>{
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/unbind-wechat`, params).then(thenResolve)
  },
  // 查询最近使用渠道
  async getLatestChannels(params = {}) {
    return await request.get(`${SERVER.LEAD_SERVICE}/api/v1/latest-channels`, { params }).then(thenResolve)
  },
  // 线索合并
  async leadMerge(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/lead-merge`, params).then(thenResolve)
  },
  // 通过线索id查询群id
  async getChatByLeadId(params) {
    return await request.get(`${SERVER.LEAD_SERVICE}/api/v1/chatGroupTask/chatByLeadId`, { params }).then(thenResolve)
  },
}
export default clueServices
