<template>
  <div class="mergeConfirm">
    <van-form ref="form" @submit.native.prevent>
      <div class="content common-block">
        <div class="common-t">
          <div class="common-t-l">{{ $t('合并信息') }}</div>
        </div>
        <div class="mergeItem">
          <div class="label">{{ $t('被合并线索：') }}</div>
          <div class="phone">{{ originMobile }}</div>
          <div class="name">{{ originName }}</div>
        </div>
        <div style="margin-bottom: 0;" class="mergeItem">
          <div class="label">{{ $t('合并至线索：') }}</div>
          <div class="phone">{{ mergeMobile }}</div>
          <div class="name">{{ mergeName }}</div>
        </div>
        <div class="common-t">
          <div class="common-t-l required">{{ $t('合并原因') }}</div>
        </div>
        <van-field
          v-model="params.reason"
          label=""
          :placeholder="$t('请输入合并原因')"
          required
          :rules="[{required: true}]"
          type="textarea"
          rows="4"
          autosize
          maxlength="200"
          show-word-limit>
        </van-field>
      </div>
      <div class="common-footer">
        <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('上一步')}}</van-button>
        <van-button class="btn submit" type="submit" @click="onSubmit">{{$t('合并')}}</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import loading from '@/utils/loading'
import clueServices from '@/services/clueServices'
export default {
  name: 'mergeClueConfirm',
  data() {
    return {
      originClueId: '',
      originName:'',
      originMobile: '',
      mergeClueId: '',
      mergeName: '',
      mergeMobile: '',
      params: {
        reason: '',
        id: '',
        mergeToId: '',
      }
    }
  },
  mounted() {
    this._data = Object.assign(this._data, this.$route.query)
    this.params.id = this.originClueId
    this.params.mergeToId = this.mergeClueId
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    onSubmit() {
      this.$refs.form.validate().then(() => {
        this.$dialog.confirm({
          title: this.$t('合并后，被合并线索自动战败，确定继续合并？'),
          confirmButtonColor: '#B9921A',
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
        }).then(async() => {
          loading.showLoading()
          await clueServices.leadMerge(this.params)
          loading.hideLoading()
          this.$toast.success(this.$t('操作成功'))
          setTimeout(() => {
            this.$router.go(-2)
          }, 1000)
        }).cancel(() => {

        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .mergeConfirm{
    .van-form{
      padding: 16px;
      .content{
        background: #fff;
        padding: 0 12px;
        .common-t{
          border-bottom: none;
          .common-t-l::before{
           background: @yellow-text;
          }
        }
        .mergeItem{
          display: flex;
          align-items: center;
          font-size: 13px;
          margin-bottom: 8px;
          .label{
            width: fit-content;
            margin-right: 4px;
          }
          .phone{
            width: fit-content;
          }
          .name{
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            position: relative;
            padding-left: 16px;
            &::before{
              position: absolute;
              width: 2px;
              height: 14px;
              top: 50%;
              left: 8px;
              transform: translateY(-50%);
              background: #D9D9D9;
              content: '';
            }
          }
        }
        .van-field{
          padding: 10px 0;
        }
      }
    }
  }
</style>
